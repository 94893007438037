import React from 'react'
import Image from 'next/image'
import {getImageResolution} from 'util/getImageResolution'
import classNames from 'classnames'
import Link from 'next/link'
import {ImageStoryblok} from 'types/storyblok-types'
import {storyblokEditable} from '@storyblok/react'
import linkTypeChecker from 'util/linkTypeChecker'

type ImageBlockProps = {
  image_url: string
  image_alt?: string
  image_size?: ImageSize
  image_link?: ImageBlockProps['image_url']
  image_link_target?: string
  roundedCorners: boolean | undefined
  blok: ImageStoryblok
}

export enum ImageSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xtra_large = 'xtra_large',
}

export const ImageBlock = ({
  image_url,
  image_alt,
  image_size,
  image_link,
  image_link_target,
  roundedCorners,
  blok,
}: ImageBlockProps) => {
  const {width, height} = getImageResolution(image_url)

  const rootClassnames = classNames('block overflow-hidden pt-8', {
    'w-full lg:w-1/4': image_size === ImageSize.small,
    'w-full lg:w-1/2': image_size === ImageSize.medium,
    'w-full lg:w-3/4': image_size === ImageSize.large,
    'w-full': image_size === ImageSize.xtra_large,
    'rounded-dats-xl': roundedCorners,
  })

  const ImageElement = () => (
    <Image
      src={image_url}
      alt={image_alt ?? ''}
      height={height}
      width={width}
      className={classNames({'rounded-dats-xl': roundedCorners})}
    />
  )
  return (
    <>
      {image_link ? (
        <Link
          href={image_link}
          target={image_link_target}
          className={rootClassnames}
          {...storyblokEditable(blok)}
        >
          <ImageElement />
        </Link>
      ) : (
        <div className={rootClassnames} {...storyblokEditable(blok)}>
          <ImageElement />
        </div>
      )}
    </>
  )
}

type ImageBlockContainerProps = {
  blok: ImageStoryblok
}
const ImageBlockContainer = ({blok}: ImageBlockContainerProps) => {
  const toImageSize = (imageSize: string) => {
    if (imageSize === 'small') {
      return ImageSize.small
    } else if (imageSize === 'medium') {
      return ImageSize.medium
    } else if (imageSize === 'large') {
      return ImageSize.large
    } else if (imageSize === 'extra_large') {
      return ImageSize.xtra_large
    } else {
      return ImageSize.xtra_large
    }
  }

  let imageLink = linkTypeChecker(blok.image_link)

  if (blok.image_link?.linktype === 'story' && !blok.image_link.id) {
    imageLink = ''
  }

  return (
    <>
      <ImageBlock
        image_url={blok.image?.filename ?? ''}
        image_alt={blok.image?.alt}
        image_size={toImageSize(blok.image_size ?? '')}
        image_link={imageLink}
        image_link_target={blok.image_link?.target}
        roundedCorners={blok.rounded_corners}
        blok={blok}
      />
    </>
  )
}
export default ImageBlockContainer
